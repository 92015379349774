.cms-container {
  .restricted {
    display: none;

    @media (max-width: 991px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
      background-color: white;
      // height: 100vh;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  aside {
    top: 56px !important;
    z-index: 2;
    height: calc(100vh - 56px);
    overflow-y: auto;
  }

  .nav-link.active,
  .nav-link:hover {
    color: gold !important;
    background-color: #495057;
  }

  .nav-link.active + .child {
    display: block;
  }

  .child {
    display: none;
  }

  .child .nav-link {
    background-color: #3b4046;
    padding-left: 2rem;
  }

  .dashboard {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 2rem - 56px);
  }

  .p-0 {
    padding: 0 !important;
  }

  .arrow {
    float: right;
    font-weight: bold;
  }
}

.table tr th:last-child {
  width: 200px;
}

.table tr td:last-child {
  width: 200px;
}

.form-alert {
  position: fixed;
  bottom: 0;
  right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  min-width: 340px;
  font-weight: bold;
  z-index: 9;
}

.table th:first-child {
  width: 50px;
}

.modal-overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.14);
  position: fixed;
}
